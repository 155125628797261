<template>
  <div style="word-break: break-word;word-break: break-all">
    <div v-if="isMark || isPaper">
      <Alert type="success" style="margin-top: 10px" v-if="question.answer">
        参考答案:
        <span slot="desc" v-html="question.answer"></span>
      </Alert>
      <Alert type="info" style="margin-top: 10px" v-if="question.analysis">
        答案解析：
        <span slot="desc" v-html="question.analysis.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" style="color: #606266"></span>
      </Alert>
      <Alert type="info" v-if="!isPaper">
        用户答案:
        <span slot="desc" v-html="value"></span>
      </Alert>
    </div>
    <div v-else>
      <Alert :type="question.resultStatus === 0 ? 'success' : 'error'" v-if="question.myPoints">
        <span style="font-weight: bold">得分/满分:&nbsp;&nbsp;{{ question.myPoints }}/{{ question.points }}</span>
      </Alert>
      <Alert :type="question.resultStatus === 0 ? 'success' : 'error'" v-if="value">
        用户答案:
        <span slot="desc" v-html="value"></span>
      </Alert>
      <Alert type="success" style="margin-top: 10px" v-if="question.question.answer">
        参考答案:
        <span slot="desc" v-html="question.question.answer"></span>
      </Alert>
      <Alert type="info" style="margin-top: 10px" v-if="question.question.analysis">
        答案解析：
        <span slot="desc" v-html="question.question.analysis.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" style="color: #606266"></span>
      </Alert>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShortAnswer',
  props: {
    question: {
      type: Object,
      default: function() {
        return {}
      }
    },
    answer: {
      type: String,
      default: ''
    },
    isMark: {
      type: Boolean,
      default: false
    },
    isPaper: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this.answer || ''
    }
  },
  watch: {
    answer(val) {
      this.value = val
    }
  }
}
</script>

<style scoped></style>
